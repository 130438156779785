@font-face {
  font-family: 'Techovier';
  src: url(../public/assets/fonts/Techovier.otf);
}

#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #fff;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

.h-50 {
  height: 50px !important;
}

.primary-text {
  color: #F7931A !important;
}

.danger-text {
  color: #FD5C46 !important;
}

.fw-300 {
  font-weight: 300 !important;
}
.fs-20{
  font-size: 20px;
}
/* Header  */

.navbar {
  height: 70px;
  position: fixed;
  width: 100%;
  z-index: 11;
  background:#fff;
}

.navbar .nav-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #0A0A0B;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
}

.navbar .nav-link:hover,
.navbar .nav-link.active {
  color: #F7931A;
  transition: all .2s ease-in-out;
}



.navbar-collapse.collapsing {
  transition: none !important;
}

.h-40 {
  height: 40px !important;
}
.header{
  padding-top: 120px;
}

/* Banner */
.banner {
  background-image: url(../public/assets/banner-bg.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 30px 0 0px 30px;
}
.inner-bg {
  background-image: url(../public/assets/banner.png);
  background-position: right bottom;
  background-size: 430px;
  background-repeat: no-repeat;
}

.banner h1 {
  font-family: 'Techovier';
  color: #0A0A0B;
  font-size: 42px;
  font-weight: 600;
  line-height: 56px;
}

.banner p {
  color: rgba(10, 10, 11, .7);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.top-text {
  background: #F7931A;
  border-radius: 8px;
  padding: 8px 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}
.chain-text{
  font-size: 28px;

}

.primary-btn {
  height: 46px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffff;
  background: #F7931A;
  border: 1px solid #F7931A;
  border-radius: 60px;
  padding: 14px 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.primary-btn:hover {
  color: #F7931A;
  background: transparent;
  transition: all .3s ease-in-out;
}

.primary-btn-outline {
  background: #DEDAD4;
  border-radius: 60px;
  display: inline-flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: black;
  padding: 14px 25px;
  border: 1px solid #DEDAD4;
  height: 46px;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.primary-btn-outline:hover {
  background: transparent;
  color: #0A0A0B;
  border-color: #0A0A0B;
  transition: all .1s ease-in-out;
}



.transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.z-1 {
  z-index: 1;
}



@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #0A0A0B;
    font-size: 36px;
    border: none;
    background: none;
  }

}


@media(max-width: 767px) {

  .navbar {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .banner {
    position: relative;
    z-index: 0;
    align-items: flex-start;
    height: auto;
    background: none;
    min-height: auto;
  }

  .banner h1 {
    margin-bottom: 20px;
    font-size: 60px;
    line-height: 80px;
  }

  .top-text.pt-100 {
    padding-top: 100px;
  }

  .banner p {
    font-size: 16px;
    line-height: 25px;
  }

  /* .banner::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000000a6;
    position: absolute;
    z-index: 0;
    overflow-y: auto;
  } */

  .banner p {
    margin-bottom: 35px;
  }

  .left-aside,
  .right-aside {
    margin-top: 45px;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #F4F3F0;
    z-index: 111;
    display: block !important;
  }

  .navbar-collapse.show {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 100vh;
    background-color: #000;
    z-index: 11;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler {
    color: #0A0A0B;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 26px;
  }

}

.company-list {
  display: flex;
  align-items: center;
  gap: 40px;
  max-width: 625px;
  flex-wrap: wrap;
}

h2 {
  color: #0A0A0B;
  font-family: 'Techovier';
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
}

/* turbo cards  */
.flex-1 {
  flex: 1;
}

.pro {
  padding: 100px 0;
}

.pro-card {
  border-radius: 12px;
  border: 1px solid #F4F3F0;
  background: #F4F3F0;
  padding: 40px 20px;
}

.pro-card h4 {
  color: #0A0A0B;
  font-family: 'Techovier';
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
}

.pro-card p {
  color: rgba(10, 10, 11, .7);
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
}


.feature h3 {
  color: #0A0A0B;
  font-family: 'Techovier';
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 25px;
}

.feature p {
  color: rgba(10, 10, 11, .7);
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
}

.f1 {
  border-radius: 8px;
  border: 1px solid #F4F3F0;
  background-color: #F4F3F0;
  background-image: url(../public/assets/bg-1.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 380px;
  padding: 60px 25px;
  margin-bottom: 30px;
  min-height: 400px;
}

.f2 {
  border-radius: 8px;
  border: 1px solid #F4F3F0;
  background-color: #F4F3F0;
  /* background-image: url(../public/assets/bg-2.png); */
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 40px 25px;
  margin-bottom: 30px;
  min-height: 400px;
}

.f3 {
  border-radius: 8px;
  border: 1px solid #F4F3F0;
  background-color: #F4F3F0;
  background-image: url(../public/assets/bg-1.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 380px;
  padding: 60px 25px;
  margin-bottom: 30px;
  min-height: 400px;
}

.f4 {
  border-radius: 8px;
  border: 1px solid #F4F3F0;
  background-color: #F4F3F0;
  /* background-image: url(../public/assets/bg-2.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 25px;
  margin-bottom: 30px;
  min-height: 400px;
}

.f5 {
  border-radius: 8px;
  border: 1px solid #F4F3F0;
  background-color: #F4F3F0;
  background-image: url(../public/assets/bg-1.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 360px;
  padding: 60px 25px;
  margin-bottom: 30px;
  min-height: 400px;
}

.eco-system {
  border-radius: 8px;
  border: 1px solid #F4F3F0;
  background: #F4F3F0;
  padding: 50px 0;
}

.eco-system p {
  color: rgba(10, 10, 11, .7);
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}

.eco-system a {
  color: #0A0A0B;
  font-size: 28px;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
}

.eco-card {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #F4F3F0;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eco-card h5 {
  color: #0A0A0B;
  font-size: 52px;
  font-weight: 500;
  line-height: 45px;
}

.eco-card p {
  color: #F7931A;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.grey-card{
  background: #F4F3F0;
  padding: 20px 25px;
  border-radius: 10px;
}

/* partner  */

.partner {
  display: flex;
  align-items: center;
  padding: 100px 0;
}

.partner-grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;

}

.partner p {
  color: #0A0A0B;
  font-size: 16px;
  font-weight: 500;
  line-height: 29px
}

.col-01{
  flex: 0 0 auto;
  width: 16.5%;
}


.partner-img {
  text-align: center;
}
.partner-img img{
  max-width: 100%;
}

/* footer  */

footer h5{
  font-weight: 600;
  margin-bottom: 20px;
}

footer ul li a {
  display: inline-block;
  padding-bottom: 10px;
  transition: all .2s ease-in-out;
}

footer ul li a:hover {
  color: #F7931A;
  transition: all .2s ease-in-out;
}




@media (max-width: 767px) {
  .header{
    padding-top: 90px;
  }
  .inner-bg{
    padding-bottom: 350px;
    background-position: right bottom;
    background-size: 330px;
    background-repeat: no-repeat;
  }
  .banner {
    background-image: url(../public/assets/banner-sm.png);
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 30px 0 0px 20px;
  }
  .banner h1 {
    font-size: 30px;
    line-height: 38px;
  }
  .fs-20 {
    font-size: 16px;
}

  .feature h3 {
    font-size: 26px;
    line-height: 34px;
    margin-bottom: 10px;
  }

  .top-text {
    font-size: 14px;
  }

  .partner {
    padding: 50px 0;
    background-size: cover;
  }

  .eco-system a {
    font-size: 16px;
  }

  .pro {
    padding: 60px 0 30px 0;
  }


  h2 {
    font-size: 30px;
    line-height: 38px;
  }


  .col-01 {
    flex: 0 0 auto;
    width: 35%;
  }

  .partner-img img {
    max-width: 100%;
  }

  .f1 {
    background-image: url(../public/assets/bg-1.png);
    background-position: bottom right;
    background-size: 260px;
    min-height: 560px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }
  
  .f2 {
    /* background-image: url(../public/assets/sm2.png); */
    background-position: top;
    background-size: contain;
    min-height: 560px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-top: 30px;
  }
  
  .f3 {
    background-image: url(../public/assets/bg-1.png);
    background-position: bottom right;
    background-size: 260px;
    min-height: 560px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }
  
  .f4 {
    /* background-image: url(../public/assets/sm4.png); */
    background-position: top;
    background-size: contain;
    min-height: 560px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-top: 30px;
  }
  
  .f5 {
    background-image: url(../public/assets/bg-1.png);
    background-position: bottom right;
    background-size: 260px;
    min-height: 560px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
  }
  
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1200px;
  }
}